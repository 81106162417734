:root {
    --black: #000000;
    --white: #FFFFFF;
    --golden: #ffd700;
    --dark-blue: #02182c;
    --light-blue: #002855;
    --h1-font-size: 2rem;
    --text-font-size: 1rem;
}

/* fonte parão do site */
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono&display=swap');

/* removendo margin e padding */
body, h1, h2, h3, p, ul, li {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

/* estilo do body */
body {
    font-family: 'JetBrains Mono', monospace;
    color: var(--white);
    font-size: var(--text-font-size);
    background-color: var(--dark-blue);
}