.navbar-container {
    display: flex;
    width: 100vw;
    height: 12vh;
    background-color: var(--light-blue);
    box-shadow: 0px 5px 20px -20px var(--black);
    align-items: center;
    text-align: center;
}

.navbar-container .navbar-icon h1{
    color: var(--golden);
    width: 20vw;
    text-decoration-color: var(--golden);
}

.navbar-container .navbar-icon img{
    display: flex;
    margin-left: 1vw;
    width: 5vw;
}

.navbar-links {
    width: 80vw;
    display: flex;
}

.navbar-container ul {
    list-style-type: none;
    display: flex;
}

.navbar-container li {
    width: 20vw;
}

/* estilo padrão do link */
.navbar-container a {
    font-size: 1.1rem;
    color: var(--golden);
    text-decoration: none;
}

/* estilo do link com mouse sobrevoando */
.navbar-container a:hover {
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color: var(--golden);
}